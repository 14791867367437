import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type ClerkVisitorStore = {
	clerkVisitor: string | null
	update: (newClerkVisitor: string) => void
}

export const useClerkVisitorStore = create<ClerkVisitorStore>()(
	devtools((set, get) => ({
		clerkVisitor: null,
		update: (newClerkVisitor: string) => {
			set(
				() => ({ clerkVisitor: newClerkVisitor }),
				false,
				'UPDATE_CLERK_VISITOR',
			)
		},
	})),
)
